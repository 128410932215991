import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './Components/LoginPage';
import Home from './Home/Home';
import { useDispatch, useSelector } from 'react-redux';
import { dbLoad } from './redux/store';


function App() {
  const dispatch =useDispatch();
  useEffect(()=>{
   dispatch(dbLoad())
   console.log("hii")
  },[])
  
  const isAuthenticated = useSelector((state) => state.isAuthenticated);
  const user  = useSelector((state)=>state.isAuthenticated);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={!isAuthenticated ? <LoginPage /> : <Home />} />
        <Route path="/logout" element={isAuthenticated ? <Home /> : <LoginPage />} />
        <Route path="/" element={isAuthenticated ? <Home /> : <LoginPage />} />
      
      </Routes>
    </Router>
  );
}

export default App;
