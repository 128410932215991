import { createStore, applyMiddleware } from 'redux';
import {thunk }from 'redux-thunk';
import axios from 'axios';
import { onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, firebaseDb } from '../firbase/init';
import { type } from '@testing-library/user-event/dist/type';
import { ref ,getDatabase,get, remove } from 'firebase/database';



// Action Types
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT = 'LOGOUT';
const SET_USER = 'SET_USER';
const SET_DB_DATA ='SET_DB_DATA';
const SET_DB_KEYS ='SET_DB_KEY';
const SET_DEVID='SET_DEVID';

// Initial State
const initialState = {
  isAuthenticated: false,
  token: null,
  errorMessage: null,
  user:{},
  db:null,
  keys:[],
};

// Reducer
const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload,
        errorMessage: null,
        devId:null
      };
    case SET_DB_DATA :
      return {
        ...state,
        db:action.payload
      };
    case SET_DB_KEYS :
      return {
          ...state,
          keys:action.payload
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case SET_USER :
      return {
        ...state,
        user:action.payload
      }
    case SET_DEVID :
        return {
          ...state,
          devId:action.payload
        }
    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        token: null,
        errorMessage: null,
      };
    default:
      return state;
  }
};

// Actions

export const deleteData =(keyName) =>async (dispatch)=>{
  const dbRef = ref(firebaseDb, '/devices/'+keyName);
  try{
  
    await remove(dbRef);
    dispatch(dbLoad());
  }
  catch(e){
    console.log(e)
  }
}

export const dbLoad = () => async (dispatch) => {
  // Initialize the database 
  console.log("jj")
  // Create a reference to the database path '/data'
  const dbRef = ref(firebaseDb, '/devices');
  try {
    // Fetch the data once
    const snapshot = await get(dbRef);
    
    if (snapshot.exists()) {
      const data = snapshot.toJSON(); 
      const dev = snapshot.val();
       
      const keys = [];
      snapshot.forEach(childSnapshot => {
        keys.push(childSnapshot.key);
      });
      
      console.log(data); 
      dispatch({ type: SET_DB_DATA, payload: dev });
      dispatch({ type: SET_DB_KEYS, payload: keys });
    } else {
      console.log('No data available');
    }
  } catch (error) {
    console.error('Error fetching data:', error);
  }
  
}

export const login = (email, password) => async (dispatch) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user =await userCredential.user;
    const token = await userCredential.user.getIdToken(); // Get Firebase Auth token
    localStorage.setItem('authToken', token);  // Store token locally
    dispatch({
      type: LOGIN_SUCCESS,
      payload: token,
    });
    store.dispatch({
      type:SET_USER,
      payload:user
    })
    dispatch({
      type
    })
  } catch (error) {
    dispatch({
      type: LOGIN_FAILURE,
      payload: 'Login failed. Please check your credentials.',
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('authToken');
  dispatch({
    type: LOGOUT,
  });
};

// Create Store
const store = createStore(authReducer, applyMiddleware(thunk));

// Function to validate token
const validateToken = async (token) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      user.getIdToken().then((token) => {
        localStorage.setItem('authToken', token);
        store.dispatch({
          type: LOGIN_SUCCESS,
          payload: token,
        });
        store.dispatch({
          type:SET_USER,
          payload:user
        })
      });
    } else {
      store.dispatch({
        type: LOGOUT,
      });
    }
  });
};

// Initialize Auth State
const initAuthState = async () => {
  const token = localStorage.getItem('authToken');
  if (token && await validateToken(token)) {
    store.dispatch({
      type: LOGIN_SUCCESS,
      payload: token,
    });
  } else {
    store.dispatch({
      type: LOGOUT,
    });
  }
};

// Initialize authentication state
initAuthState();

export default store;
