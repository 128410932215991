import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/store';

const Navbar = () => {
  
  const user =useSelector((state)=>state.user);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };


  return (
    <div className="navbar">
      <div className="user-box"> 
        <h1 className="college-name">Cyber Security Demo</h1>

        <div className="user-box">
        <input type="text" placeholder="Search..." className="search-bar" />
      </div>
      </div>
      
      <div >
      
      </div>
      <div className="user-box">
      
        <img src="https://cdn-icons-png.flaticon.com/512/149/149071.png" alt="User" className="user-image" />
        <span className="user-name"><strong>{user.email.split('@')[0]}</strong></span>
        <button className='Logout-button' onClick={handleLogout} 
      > Logout
      </button>
      </div>
    </div>
  );
};

export default Navbar;
