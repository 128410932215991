import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DataTable from './DataTable';

import { PhoneIcon, UserIcon, ChatBubbleLeftIcon, VideoCameraIcon, DocumentPlusIcon, ArchiveBoxIcon, PhotoIcon } from '@heroicons/react/24/outline';


function SpecificDevice({key}) {
    const selectedDev= useSelector(state=>state.devId);
    const db = useSelector(state=>state.db);
    const dbKeys = useSelector(state=>state.keys);
    const [dataOf,setDataOf]=useState('callLogs');
    const [tableHead,setTableHead]=useState([]);

    useEffect(() => {
        const thead = [];
        if (db[dbKeys[selectedDev]] && db[dbKeys[selectedDev]][dataOf]) {
          const data = db[dbKeys[selectedDev]][dataOf];
          
          // Check if data is available and not an empty array
          if (Array.isArray(data) && data.length > 0) {
            // Push the keys of the first object in the array as headers
            Object.keys(data[0]).forEach(key => thead.push(key));
          }
        }
        setTableHead(thead); // Set the table header state
      }, [dataOf, selectedDev, db, dbKeys]);
  return (
   <>
   <div className='container'>
    <h2>
      <span style={{fontWeight:'light',color:'grya'}} >Viewing : </span>  {dbKeys[selectedDev]}
    </h2>
    <p>Currently Viewing 100 in each</p>
    <div className='data-div'>
      <ul>
        <li>
        <ul>
            <li className={dataOf === 'callLogs' ? `active` : ''} onClick={() => setDataOf('callLogs')}>
                <PhoneIcon className="h-5 w-5 inline" /> Call Logs
            </li>
            <li className={dataOf === 'contacts' ? `active` : ''} onClick={() => setDataOf('contacts')}>
                <UserIcon className="h-5 w-5 inline" /> Contacts
            </li>
            <li className={dataOf === 'sms' ? `active` : ''} onClick={() => setDataOf('sms')}>
                <ChatBubbleLeftIcon className="h-5 w-5 inline" /> Text Sms
            </li>
            <li className={dataOf === 'recordings' ? `active` : ''} onClick={() => setDataOf('recordings')}>
                <VideoCameraIcon className="h-5 w-5 inline" /> Call Recordings
            </li>
            <li className={dataOf === 'gallery' ? `active` : ''} onClick={() => setDataOf('gallery')}>
                <PhotoIcon className="h-5 w-5 inline" /> Gallery
            </li>
            <li className={dataOf === 'documents' ? `active` : ''} onClick={() => setDataOf('documents')}>
                <DocumentPlusIcon className="h-5 w-5 inline" /> Documents
            </li>
            <li className={dataOf === 'whatsapp' ? `active` : ''} onClick={() => setDataOf('whatsapp')}>
                <ArchiveBoxIcon className="h-5 w-5 inline" /> Whatsapp
            </li>
        </ul>
        </li>
        <li>
          {(dataOf=='whatsapp'||dataOf=='recordings'||dataOf=='documents'||dataOf=='gallery') && <div
        style={{
          margin: 'auto',
          minHeight: '400px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <p style={{ fontSize: '20px' }}>
          {dataOf !== 'recordings' &&
            dataOf !== 'gallery' &&
            dataOf !== 'documents' &&
            dataOf !== 'whatsapp' && (
              <h3 style={{ fontSize: '30px', fontWeight: 'normal' }}>
                Unable to Load Data
              </h3>
            )}
          {(dataOf === 'recordings' ||
            dataOf === 'gallery' ||
            dataOf === 'documents' ||
            dataOf === 'whatsapp') && (
            <h3 style={{ fontSize: '40px' }}>We respect your privacy</h3>
          )}
        </p> 
        <img src="https://cdn-icons-png.flaticon.com/256/7650/7650342.png" alt="error icon" />
      </div>}
           <DataTable data={ (dataOf=='whatsapp'||dataOf=='recordings'||dataOf=='documents'||dataOf=='gallery')?[]: db[dbKeys[selectedDev]][dataOf]} dataOf={dataOf} tableHead={tableHead} />         
           
        </li>
      </ul>
    </div>
   </div>
   </>
  )
}

export default SpecificDevice