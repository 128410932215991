// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { getDatabase, ref, set } from 'firebase/database';


// Your web app's Firebase configuration (replace with your actual config from Firebase Console)
const firebaseConfig = {
    apiKey: "AIzaSyB6m4MpJNcS0BwqHtSXE2mlT9qCAjUPoHo",
    authDomain: "cyber-security-app-a53c7.firebaseapp.com",
    databaseURL: "https://cyber-security-app-a53c7-default-rtdb.firebaseio.com",
    projectId: "cyber-security-app-a53c7",
    storageBucket: "cyber-security-app-a53c7.appspot.com",
    messagingSenderId: "53992918028",
    appId: "1:53992918028:web:6323d5a95c1e74ce7bf229",
    measurementId: "G-WXZV021KPV"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const firebaseDb = getDatabase(app,'https://cyber-security-app-a53c7-default-rtdb.firebaseio.com');  

// Export the auth module for use in other files
export const auth = getAuth(app);
