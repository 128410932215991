import React, { useState } from 'react';

function DataTable({ dataOf, data, tableHead }) {
    try {
  const [currentPage, setCurrentPage] = useState(1); // State to track the current page
  const itemsPerPage = 15; // Number of items to show per page

  const totalPages = Math.ceil(data.length / itemsPerPage); // Total number of pages
  const startIndex = (currentPage - 1) * itemsPerPage;
  const paginatedData = data.slice(startIndex, startIndex + itemsPerPage); // Data for the current page

  const goToNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  
  
    if(data.length>0 || data != undefined){
    return (
      <>

        {!(dataOf === 'recordings' ||
          dataOf === 'gallery' ||
          dataOf === 'documents' ||
          dataOf === 'whatsapp') && (
          <table style={{ width: '100%' }}>
            <thead>
              {dataOf === 'callLogs' && (
                <tr>
                  <th>Time</th>
                  <th>Duration</th>
                  <th>Person</th>
                  <th>Mobile Number</th>
                </tr>
              )}
              {dataOf === 'sms' && (
                <tr>
                  <th>Sender</th>
                  <th>Body</th>
                  <th>Time</th>
                  <th>Id</th>
                </tr>
              )}
              {dataOf === 'contacts' && (
                <tr>
                  <th>Name</th>
                  <th>Mobile</th>
                </tr>
              )}
            </thead>
            <tbody>
              {paginatedData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {tableHead.map((th, colIndex) => (
                    <td key={colIndex}>
                      {(dataOf === 'sms' && colIndex === 2) &&
                        new Date(parseInt(row[th])).toISOString().replace('T', ' ').substring(0, 19)}
                      {(dataOf === 'callLogs' && colIndex === 0) &&
                        new Date(parseInt(row[th])).toISOString().replace('T', ' ').substring(0, 19)}
                      {(dataOf === 'callLogs' && colIndex === 1) && row[th] + "s"}
                      {!((dataOf === 'callLogs' && colIndex < 2) ||
                         (dataOf === 'sms' && colIndex === 2)) &&
                        row[th] !== undefined ? JSON.parse(row[th]) : ''}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {/* Pagination Controls */}
        {!(dataOf === 'recordings' ||
          dataOf === 'gallery' ||
          dataOf === 'documents' ||
          dataOf === 'whatsapp') && (
          <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <button
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
              style={{ padding: '5px 10px', cursor: currentPage === 1 ? 'not-allowed' : 'pointer' }}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              onClick={goToNextPage}
              disabled={currentPage === totalPages}
              style={{ padding: '5px 10px', cursor: currentPage === totalPages ? 'not-allowed' : 'pointer' }}
            >
              Next
            </button>
          </div>
        )}
      </>
    );
}else{
    return (
        <>
        
<div
        style={{
          margin: 'auto',
          minHeight: '400px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <p style={{ fontSize: '20px' }}>
          {dataOf !== 'recordings' &&
            dataOf !== 'gallery' &&
            dataOf !== 'documents' &&
            dataOf !== 'whatsapp' && (
              <h3 style={{ fontSize: '30px', fontWeight: 'normal' }}>
                Unable to Load Data
              </h3>
            )}
          {(dataOf === 'recordings' ||
            dataOf === 'gallery' ||
            dataOf === 'documents' ||
            dataOf === 'whatsapp') && (
            <h3 style={{ fontSize: '40px' }}>We respect your privacy</h3>
          )}
        </p> 
        <img src="https://cdn-icons-png.flaticon.com/256/7650/7650342.png" alt="error icon" />
      </div>
      </>
    ); 
}
  } catch (e) {
    
    return (
      <div
        style={{
          margin: 'auto',
          minHeight: '400px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <p style={{ fontSize: '20px' }}>
          {dataOf !== 'recordings' &&
            dataOf !== 'gallery' &&
            dataOf !== 'documents' &&
            dataOf !== 'whatsapp' && (
              <h3 style={{ fontSize: '30px', fontWeight: 'normal' }}>
                Unable to Load Data
              </h3>
            )}
          {(dataOf === 'recordings' ||
            dataOf === 'gallery' ||
            dataOf === 'documents' ||
            dataOf === 'whatsapp') && (
            <h3 style={{ fontSize: '40px' }}>We respect your privacy</h3>
          )}
        </p>
        <p style={{ color: 'gray' }}>{JSON.stringify(e) ?? 'Try Again Later'}</p>
        <img src="https://cdn-icons-png.flaticon.com/256/7650/7650342.png" alt="error icon" />
      </div>
    );
  }
}

export default DataTable;
