import React from 'react';
import NavBar from '../Home/NavBar';
import { useSelector } from 'react-redux';

const Dashboard = () => {
  const db = useSelector(state=>state.db);
  const dbKeys = useSelector(state=>state.keys);
  return (
    <>
    <div className="dashboard-container">
      
      <div className="card">
        <h3>Devices Connected</h3>
        <p>
          {db != null ?<><p>{dbKeys.length}</p></>:<></>}
         </p>
      </div>

      
      <div className="card">
        <h3>Devices Online</h3>
        <p>0</p>
      </div>
      
      </div> 
      <table>
       <tr>
        <th>
          #
        </th>
        <th>
          Device
        </th>
        <th>
          Status
        </th>
       </tr>
       <tbody> 
        {dbKeys.map((key,index)=>
          <tr key={index}>
              <td>{index}</td>
              <td>{key}</td>
              <td style={{color:'green'}}>Online</td>
          </tr>
           )}
         
        </tbody>
      </table>
      
      </>
  );
};

export default Dashboard;
