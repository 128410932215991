import React from 'react'
import NavBar from './NavBar'
import Sidebar from './SideBar'


const Home = () => {
  
  return (
    <div>

        <div className='Home'>
            <Sidebar/>
        </div>
        


    </div>
  )
}

export default Home