
    import { DevicePhoneMobileIcon, EyeIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
    import React from 'react'
    import { useDispatch, useSelector } from 'react-redux';
    import { useNavigate } from 'react-router-dom';
import { deleteData } from '../redux/store';
    

    function ConnectedDevices({clickTo,setDid}) {
    const connectedDevices=[ 
    {name:'Device a',model:"Redmi Note 9 pro",dataFetchedAt:'2024-09-12 10:10:00'},
    {name:'Device a',model:"Redmi Note 9 pro",dataFetchedAt:'2024-09-12 10:10:00'},
    ];
    const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const db = useSelector(state=>state.db);
    const dbKeys = useSelector(state=>state.keys);
    return (
        <div className="container">
        <h2>Connected Devices</h2>
        <ul>
            {dbKeys.map((key,index)=>
            <li>
            <div className='deviceCard'>
                <ul>
                    <li>
                    <p>{key}</p>
                    <br/>
                    <p>{db[key].lastSeen}</p>
                    
                    </li>
                    <li>
                        <DevicePhoneMobileIcon/>
                    </li>
                </ul>
                <div className='footer'>
                <ul style={{display:"flex",flexDirection:'row',justifyContent:'end'}}>
                    <li style={{marginInlineEnd:'10px'}}>
                        <EyeIcon 
                        onClick={()=>{
                            dispatch({type:'SET_DEVID',payload:index})
                            clickTo(`/deviceView?dId=${index}`) 
                        }
                        }

                        />
                    </li>
                    <li>
                        <TrashIcon onClick={()=> dispatch(deleteData(key))} />
                    </li>
                </ul>
                </div>
            </div>
            </li>)}
        </ul>
        </div>
    )
    }

    export default ConnectedDevices