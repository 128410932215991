import React, { useState } from 'react';
import Dashboard from '../Components/Dashboard';
import Student from '../Components/Students';
import Class from '../Components/Class';
import Attendance from '../Components/Attendance';
import NavBar from './NavBar';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../redux/store';
import { CircleStackIcon, HomeIcon, UserCircleIcon } from '@heroicons/react/24/outline';
import ConnectedDevices from '../Components/ConnectedDevices';
import SpecificDevice from '../Components/SpecificDevice';



const Sidebar = () => {
  
  const user =useSelector((state)=>state.user);

  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
  };
    
  const [ShowComponent, setShowComponent] = useState('Dashboard');
  const [dId,setDId]=useState(0);

  const renderComponent = (dId) => {
    switch (ShowComponent) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Database':
        return <ConnectedDevices clickTo={()=>setShowComponent('deviceView')} setDid={()=>setDId}  />;
      case 'Student':
        return <Student />;
      case 'Class':
        return <Class />;
      case 'Attendance':
        return <Attendance />;
      case 'deviceView':
        return <SpecificDevice key={dId}/> ; 
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className='side-contents'>
      
      <div className="sidebar-container">
      <div className='account-box'>
        <ul>
          <li>
            <UserCircleIcon/>
          </li>
          <li>
            {user.email}
          </li>
        </ul>
      </div>
            <div className="sidebar-item" onClick={() => setShowComponent('Dashboard')}> <HomeIcon/> Dashboard </div>
            <div className="sidebar-item" onClick={() => setShowComponent('Database')}> <CircleStackIcon/> Database</div>
            </div>
      <div className='Content-body-box'>
          <div>
            <NavBar/>
          </div>
          <div>
            <div className="content-container">
                {renderComponent()}
            </div>
          </div> 
      </div>
      
    </div>
  );
};

export default Sidebar;
